import { useEffect } from "react";
import { Box, Button } from "@chakra-ui/react";
import { Stack, HStack, VStack } from "@chakra-ui/react";
import MyDatePicker from "../calendar/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserPanel from "../../components/UserPanel.js";

import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faCarSide } from "@fortawesome/free-solid-svg-icons";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faCalendarPlus } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faListUl } from "@fortawesome/free-solid-svg-icons";

import { useDisclosure, useToast } from "@chakra-ui/react";
import { format } from "date-fns";
import { useNavigate, Link, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";
import NearestAvailable from "../calendar/dash/NearestAvailable";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetAGarageMutation } from "../garages/garagesApiSlice";
import { setGarage } from "../garages/selectedGarageSlice";
import PageHeader from "../../components/PageHeader";
import { setDate } from "../calendar/selectedDateSlice.js";

const TopMenu = () => {
  const [getAGarage, { data: garage, isLoading, isSuccess, isError, error }] =
    useGetAGarageMutation();
  const cDay = format(new Date(), "dd");
  const cMonth = format(new Date(), "MM");
  const cYear = format(new Date(), "yyyy");
  const { garageId, day } = useParams();
  const path = useParams()["*"];
  const isServiceModalOpen = useSelector(
    (state) => state.isServiceModalOpen.isServiceModalOpen
  );
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const UserInfo = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const selectedGarage = useSelector((state) => state.selectedGarage.garage);
  useEffect(() => {
    getAGarage({ gid: garageId });
  }, [garageId]);
  if (isSuccess) {
    dispatch(setGarage(garage));
  }
  // useEffect(() => {
  //   if (garage) {
  //     setGarage(garage);
  //   }
  // }, [garage]);
  const carsButton = (
    <>
      {UserInfo?.roles?.isadmin?.filter((g) => g._id === garageId).length >
        0 && (
        <VStack className="menu_wrapper">
          <Button
            onClick={() => navigate(`/dash/${garageId}/vehicles`)}
            className="primaryBackground"
            color="gray.300"
          >
            <FontAwesomeIcon icon={faCarSide} />
          </Button>
          <p
            className={
              path.includes("vehicles") ? "small menu selected" : "small menu"
            }
          >
            pojazdy
          </p>
        </VStack>
      )}
    </>
  );
  const userPanelButton = (
    <Box h={"66px"} className="menu_wrapper">
      <UserPanel garage={garage} />
    </Box>
  );
  const homeButton = (
    <Box h={"66px"} className="menu_wrapper">
      <Button margin-bottom="50px" onClick={() => navigate(`/`)} bg="#12b4ff">
        <FontAwesomeIcon color="#ffffff" icon={faHouse} />
      </Button>
    </Box>
  );
  const usersButton = (
    <>
      {UserInfo?.roles?.isadmin?.filter((g) => g._id === garageId).length >
        0 && (
        <VStack className="menu_wrapper">
          <Button
            onClick={() => navigate(`/dash/${garageId}/users`)}
            className="primaryBackground"
            color="gray.300"
          >
            <FontAwesomeIcon icon={faAddressBook} />
          </Button>
          <p
            className={
              path.includes("users") ? "small menu selected" : "small menu"
            }
          >
            Klienci
          </p>
        </VStack>
      )}
    </>
  );
  const itemsButton = (
    <>
      {(UserInfo?.roles?.isadmin?.filter((g) => g._id === garageId).length >
        0 ||
        UserInfo?.roles?.isemployee?.filter((g) => g._id === garageId).length >
          0) && (
        <VStack className="menu_wrapper">
          <Button
            onClick={() => navigate(`/dash/${garageId}/items`)}
            className="primaryBackground"
            color="gray.300"
          >
            <FontAwesomeIcon icon={faListUl} />
          </Button>
          <p
            className={
              path.includes("items") ? "small menu selected" : "small menu"
            }
          >
            części
          </p>
        </VStack>
      )}
    </>
  );
  const commentButton = (
    <>
      {(UserInfo?.roles?.isadmin?.filter((g) => g._id === garageId).length >
        0 ||
        UserInfo?.roles?.isemployee?.filter((g) => g._id === garageId).length >
          0) && (
        <VStack className="menu_wrapper">
          <Button
            onClick={() => navigate(`/dash/${garageId}/sms`)}
            className="primaryBackground"
            color="gray.300"
          >
            <FontAwesomeIcon icon={faComment} />
          </Button>
          <p
            className={
              path.includes("sms") ? "small menu selected" : "small menu"
            }
          >
            sms
          </p>
        </VStack>
      )}
    </>
  );
  const datePicker = (
    <>
      <MyDatePicker from={"main"} />
    </>
  );
  const calendarButton = (
    <>
      {(UserInfo?.roles?.isadmin?.filter((g) => g._id === garageId).length >
        0 ||
        UserInfo?.roles?.isemployee?.filter((g) => g._id === garageId).length >
          0) && (
        <VStack className="menu_wrapper">
          <Button
            onClick={() => {
              dispatch(setDate(null));
              navigate(`/dash/${garageId}/calendar/${cDay}/${cMonth}/${cYear}`);
            }}
            className="primaryBackground"
            color="gray.300"
          >
            <FontAwesomeIcon icon={faCalendarDays} />
          </Button>
          <p
            className={
              path.includes("calendar") ? "small menu selected" : "small menu"
            }
          >
            kalendarz
          </p>
        </VStack>
      )}
    </>
  );
  const nearestButton = (
    <Box h={"60px"}>
      {UserInfo?.roles?.isadmin?.filter((g) => g._id === garageId).length >
        0 && (
        <NearestAvailable key="nearest" onOpen={onOpen}></NearestAvailable>
      )}
    </Box>
  );
  let buttonContent;
  buttonContent = (
    <>
      <HStack padding="10px">
        {userPanelButton}
        {homeButton}
        {usersButton}
        {carsButton}
        {itemsButton}
        {calendarButton}
        {commentButton}
        {day && nearestButton}
        {day && datePicker}
        <PageHeader className="pageHeader" />
      </HStack>
    </>
  );

  const content = (
    <>
      <div>{buttonContent}</div>
    </>
  );

  return content;
};
export default TopMenu;
