import { useGetAllServicesMutation } from "./servicesApiSlice";
import { useGetServicesQuery } from "./servicesApiSlice";
import Service from "./Service";
import useAuth from "../../hooks/useAuth";
import useTitle from "../../hooks/useTitle";
import { useParams } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import { useDispatch } from "react-redux";
import { setHour } from "./selectedHourSlice";
import { setMinute } from "./selectedMinuteSlice";
import ItemsModal from "../items/ItemsModal";
import { setIsServiceModalOpen } from "./isServiceModalOpenSlice";
import { setVehicle } from "../vehicles/selectedVehicleSlice";
import { setServiceModalMode } from "./serviceModalModeSlice";
import { setItemsId } from "../items/selectedItemsSlice";
import { AddIcon } from "@chakra-ui/icons";
import { setStId } from "../st/selectedStSlice";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  HStack,
  Progress,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { setUserObj } from "../users/selectedUserSlice";
import { format } from "date-fns";
import ServiceRow from "./ServiceRow";
import NewServiceModal from "./NewServiceModal";
import { setDate } from "../calendar/selectedDateSlice";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import { setIsServiceAnonymous } from "./isServiceAnonymousSlice";
import {
  setEditedServiceId,
  setEditedServiceNotes,
} from "./editedServiceIdSlice";
let socket;
const ServicesList = () => {
  const [socketConnected, setSocketConnected] = useState(false);
  // const { username, isManager, isAdmin } = useAuth();
  const UserInfo = useAuth();
  const saveService = useSelector((state) => state.saveService.save);
  const [toApprove, setToApprove] = useState(false);
  const { garageId, day, month, year } = useParams();
  const [content, setContent] = useState(
    <>
      <Progress
        width={"100%"}
        height={"2px"}
        isIndeterminate
        colorScheme="blue"
      />
    </>
  );
  let idz = [];
  const dispatch = useDispatch();

  const [getServices, { data: updatedServices }] = useGetAllServicesMutation();

  const {
    data: services,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetServicesQuery(
    { gid: garageId, id: garageId, day, month, year },
    "servicesList",
    {
      pollingInterval: 15000,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    socket = io("https://warsztap-api.onrender.com", {
      transports: ["websocket", "polling", "flashsocket"],
    });
    socket.emit("setup", UserInfo);
    socket.emit("join room", "updates");
    socket.on("xxx", () => {
      // // // // console.log("############################");
      // // // // console.log("zapisuje");
      getServices({ id: garageId, day, month, year });
    });
    socket.on("connected", () => setSocketConnected(true));
  });
  useEffect(() => {
    setContent(
      <>
        <Progress
          width={"100%"}
          height={"2px"}
          isIndeterminate
          colorScheme="blue"
        />
      </>
    );
  }, [day]);
  useEffect(() => {
    // getServices({ id: garageId, day, month, year });
    dispatch(
      setDate(format(new Date(`${year}/${month}/${day}`), "dd/MM/yyyy"))
    );
  }, []);
  // useEffect(() => {
  //   getServices({ id: garageId, day, month, year });
  // }, [saveService]);

  // const getAllServices = async () => {
  //   await getServices({ id: garageId, day, month, year }).then((data) => {
  //     // // // // console.log(`getServices`);
  //     // // // // console.log(data);
  //   });
  // };
  useTitle("WARSZTApp: kalendarz");

  const setUpModal = (hour, mode) => {
    dispatch(setEditedServiceNotes(""));
    dispatch(setEditedServiceId(null));
    dispatch(setIsServiceAnonymous(false));
    dispatch(setStId([]));
    dispatch(setItemsId([]));
    dispatch(setUserObj(null));
    dispatch(setUserObj(null));
    dispatch(setVehicle(null));
    dispatch(setServiceModalMode(mode));
    dispatch(setIsServiceModalOpen(true));
    dispatch(
      setDate(format(new Date(`${year}/${month}/${day}`), "dd/MM/yyyy"))
    );
    setTime(hour);
  };

  // if (isLoading) content = <PulseLoader color={"#FFF"} />;

  if (isError) {
    // content = <p className="errmsg">{error?.data?.message}</p>;
  }
  const setTime = (hour) => {
    dispatch(setHour(hour));
    dispatch(setMinute("00"));
  };
  const preferences = useSelector((state) => state.preferences);
  const hours = [];
  for (let i = preferences.hourOpen; i <= preferences.hourClose; i++) {
    hours.push(i);
  }
  // // console.log(`preferences`);
  // // console.log(hours);
  // // console.log(preferences.hourClose - preferences.hourOpen);
  // // console.log(`preferences`);
  useEffect(() => {
    if (services) {
      const { ids, entities } = services;
      if (ids?.length > 0) {
        // // // // // console.log("idz1");
        let getIdz = () => {
          const b = hours.map((hour) =>
            ids.filter((serviceId) => entities[serviceId].hour == hour)
          );
          return b;
        };
        let a = getIdz();
        [...idz] = a;
      } else {
        // // // // // console.log("idz2");
        [...idz] = hours.map((h) => []);
      }
      let h = 6;
      const tableContent =
        idz?.length &&
        idz.map((serviceId, i) => {
          let serviceStatusColor = "#edf9ff";
          if (entities[serviceId]?.completed === "done") {
            // setToApprove(false);
            serviceStatusColor = "#e1ffd9";
          } else if (entities[serviceId]?.completed === "approved") {
            // setToApprove(false);
            serviceStatusColor = "#edf9ff";
          } else if (entities[serviceId]?.completed === "suggested") {
            // setToApprove(true);
            serviceStatusColor = "#ffd9c7";
          } else {
            // setToApprove(true);
            serviceStatusColor = "#edf9ff";
          }
          h++;
          if (serviceId.length <= 1) {
            if (entities[serviceId]) {
            }
            // // // // // console.log("serviceId");
            // // // // // console.log(serviceId);
            const open = false;
            return (
              <Tr key={`tr${i}`}>
                <Td key={`h${i}`} className="servicehour index0">
                  <HStack>
                    <Button
                      // isDisabled={hours[i] === 11 || hours[i] === 14}
                      color={"#ffffff"}
                      size={"xs"}
                      bg="#12b4ff"
                      colorScheme="cyan"
                      onClick={() => setUpModal(hours[i], "add")}
                      key={`td${i}`}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <div>{hours[i]}</div>
                  </HStack>
                </Td>
                <ServiceRow
                  // index={index}
                  toApprove={entities[serviceId]?.completed}
                  serviceStatusColor={serviceStatusColor}
                  myClass={`rowspan`}
                  key={`sr${i}`}
                  appointment={entities[serviceId]}
                />
              </Tr>
            );
          } else {
            const tc = serviceId.map((id, index) => {
              if (entities[id]?.completed === "done") {
                // setToApprove(false);
                serviceStatusColor = "#e1ffd9";
              } else if (entities[id]?.completed === "approved") {
                // setToApprove(false);
                serviceStatusColor = "#edf9ff";
              } else if (entities[id]?.completed === "suggested") {
                // setToApprove(true);
                serviceStatusColor = "#ffd9c7";
              } else {
                // setToApprove(true);
                serviceStatusColor = "#edf9ff";
              }
              // // // // // console.log("id");
              // // // // // console.log(id);
              if (index == 0) {
                return (
                  <Tr key={`tr${index}`}>
                    <Td
                      key={`h${index}`}
                      rowSpan={serviceId.length}
                      className={`rowspans ${
                        index === 0 || !index ? " index0" : " index1"
                      }`}
                    >
                      <HStack>
                        <Button
                          // isDisabled={hours[i] === 11 || hours[i] === 14}
                          color={"#ffffff"}
                          size="xs"
                          colorScheme="cyan"
                          onClick={() => setUpModal(hours[i], "add")}
                          key={`tr${index}`}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                        <div>{hours[i]}</div>
                      </HStack>
                    </Td>
                    <ServiceRow
                      index={index}
                      length={serviceId.length}
                      toApprove={entities[id]?.completed}
                      serviceStatusColor={serviceStatusColor}
                      myClass={`rowspan`}
                      key={`sr${index}`}
                      appointment={entities[id]}
                    />
                  </Tr>
                );
              } else {
                return (
                  <Tr key={`ttrsr${index}`}>
                    <ServiceRow
                      index={index}
                      length={serviceId.length}
                      toApprove={entities[id]?.completed}
                      serviceStatusColor={serviceStatusColor}
                      myClass={""}
                      key={`trsr${index}`}
                      appointment={entities[id]}
                    />
                  </Tr>
                );
              }
            });
            return tc;
          }
        });

      setContent(
        <>
          <section className="">
            <NewServiceModal key="modal1" />
            <ItemsModal key="modal2" />
            <TableContainer maxH={"82vh"} overflowY={"scroll"}>
              <Table className="serviceTr" variant="simple">
                <Thead>
                  <Tr>
                    <Th className="" textAlign="center">
                      godz
                    </Th>
                    <Th textAlign="center">min</Th>
                    <Th textAlign="center"></Th>
                    <Th textAlign="center">klient</Th>
                    <Th textAlign="center">części</Th>
                    <Th textAlign="center">usługa</Th>
                    <Th textAlign="center">uwagi</Th>
                    <Th textAlign="center"></Th>

                    {/* <Th color="#aaa" fontSize="16px" className="thHour">
                  godzina
                </Th>
                <Th color="#aaa" fontSize="16px" className="thMinute">
                  minuta
                </Th>
                <Th color="#aaa" fontSize="16px">
                  klient
                </Th>
                <Th color="#aaa" fontSize="16px">
                  item
                </Th>
                <Th color="#aaa" fontSize="16px">
                  usługa
                </Th>
                <Th color="#aaa" fontSize="16px">
                  uwagi
                </Th>
                <Th color="#aaa" fontSize="16px"></Th> */}
                  </Tr>
                </Thead>
                <Tbody>{tableContent}</Tbody>
                <Tfoot>
                  <Tr>
                    <Th className="" textAlign="center">
                      godz
                    </Th>
                    <Th textAlign="center">min</Th>
                    <Th textAlign="center"></Th>
                    <Th textAlign="center">klient</Th>
                    <Th textAlign="center">części</Th>
                    <Th textAlign="center">usługa</Th>
                    <Th textAlign="center">uwagi</Th>
                    <Th textAlign="center"></Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </section>
        </>
      );
    }
  }, [services]);

  return (
    <>
      {!services && (
        <Progress
          width={"100%"}
          height={"2px"}
          isIndeterminate
          colorScheme="blue"
        />
      )}{" "}
      {content}
    </>
  );
};
export default ServicesList;
