import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Box,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Spinner,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import StsListItem from "../st/StsListItem";
import VehiclesTRow from "../vehicles/VehiclesTRow";
import TRow from "../items/TRow";

const ServiceDetailSRow = ({ service, mode }) => {
  const itemsList = service?.items?.map((item) => (
    <TRow mode={mode} view="service" item={item} />
  ));
  //   //console.log(service);
  return (
    <Tr
      className=""
      p={0}
      m={0}
      backgroundColor={service?.completed === "done" ? "green.50" : "blue.50"}
      borderColor={service?.completed === "done" ? "green.100" : "blue.100"}
      borderWidth="1px 0px"
      borderStyle={"solid"}
    >
      <Td
        backgroundColor={service?.completed === "done" ? "green.50" : "blue.50"}
        borderColor={service?.completed === "done" ? "green.100" : "blue.100"}
        borderWidth="1px 0px"
        borderStyle={"solid"}
      ></Td>
      <Td
        backgroundColor={service?.completed === "done" ? "green.50" : "blue.50"}
        borderColor={service?.completed === "done" ? "green.100" : "blue.100"}
        borderWidth="1px 0px"
        borderStyle={"solid"}
      >
        <p>
          <pspan className=" bold">{service?.date} </pspan>
          <pspan className=" gray">
            {" "}
            {service?.hour}:{service?.minute}
          </pspan>
          <p className="gray bold">{service?.user?.username}</p>
          <p className=" gray upper">{service?.vehicle?.reg}</p>
        </p>
        {/* <p>{service._id}</p> */}
      </Td>
      <Td
        backgroundColor={service?.completed === "done" ? "green.50" : "blue.50"}
        borderColor={service?.completed === "done" ? "green.100" : "blue.100"}
        borderWidth="1px 0px"
        borderStyle={"solid"}
      >
        {service?.st?.map((st) => (
          <StsListItem st={st} />
        ))}
      </Td>
      {/* <Td
        p={0}
        m={0}
        backgroundColor={service?.completed === "done" ? "green.50" : "blue.50"}
        borderColor={service?.completed === "done" ? "green.100" : "blue.100"}
        borderWidth="0px 1px"
        borderStyle={"solid"}
      >
        <Box
          p={0}
          m={0}
          borderRadius="0px"
          overflowY="auto"
          overflowX="auto"
          maxWidth="55vw"
        >
          <TableContainer
            overflowY="scroll"
            className="left w100 servicesDetailsTable"
          >
            <Table variant="simple">
              <Tbody> */}
      {itemsList?.length > 0 ? (
        itemsList
      ) : (
        <Td
          backgroundColor={
            service?.completed === "done" ? "green.50" : "blue.50"
          }
          borderColor={service?.completed === "done" ? "green.100" : "blue.100"}
          borderWidth="1px 0px"
          borderStyle={"solid"}
        ></Td>
      )}
      {/* </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Td> */}
      <Td
        backgroundColor={service?.completed === "done" ? "green.50" : "blue.50"}
        borderColor={service?.completed === "done" ? "green.100" : "blue.100"}
        borderWidth="1px 0px"
        borderStyle={"solid"}
      >
        <Box
          p={"5px 10px 7px 10px"}
          borderRadius={5}
          bg={service?.completed === "done" ? "green.200" : "blue.200"}
        >
          <p className="white">{service?.completed}</p>
        </Box>{" "}
      </Td>
    </Tr>
  );
};

export default ServiceDetailSRow;
