import { useEffect, useState } from "react";
import { useGetServicesByItemIdMutation } from "./servicesApiSlice";
import {
  Box,
  Button,
  Spinner,
  Table,
  Tbody,
  Th,
  Thead,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import ServiceDetailSRow from "./ServiceDetailsRow";
import { useDispatch, useSelector } from "react-redux";
import { setIsItemsModalOpen } from "../items/isItemsModalOpenSlice";
import { setItemModalMode } from "../items/itemModalModeSlice";
import { setSelectedItemDetails } from "../items/selectedItemDetailsSlice";
import useAuth from "../../hooks/useAuth";

const ServicesContent = ({ user, itemId, mode }) => {
  const UserInfo = useAuth();

  const { garageId } = useParams();
  const [servicesContent, setServicesContent] = useState(<></>);
  const [
    getServicesByItemId,
    { data: services, isLoading, isSuccess, isError, error },
  ] = useGetServicesByItemIdMutation();
  let servicesTableContent = "";
  const dispatch = useDispatch();
  const isItemsModalOpen = useSelector(
    (state) => state.isItemsModalOpen.isItemsModalOpen
  );
  const selectedItemDetails = useSelector(
    (state) => state.selectedItemDetails.selectedItemDetails
  );
  const findServices = () => {
    setServicesContent(<Spinner />);
    getServicesByItemId({ uid: UserInfo?._id, garageId: garageId, itemId });
  };
  const clearServices = async () => {
    setServicesContent(
      <Button
        onClick={() => {
          findServices(user?._id);
        }}
        className="primaryBackground"
        color="gray.300"
        size={"sm"}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </Button>
    );
  };
  useEffect(() => {
    setServicesContent(
      <Button
        onClick={() => {
          findServices(user?._id);
        }}
        className="primaryBackground"
        color="gray.300"
        size={"sm"}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </Button>
    );
  }, [user]);
  useEffect(() => {
    if (selectedItemDetails) {
    }
  }, [selectedItemDetails]);
  if (isSuccess) {
    // console.log(services);
  }
  if (isError) {
    console.log(error);
  }
  useEffect(() => {
    if (services) {
      const { ids, entities } = services;
      if (ids?.length > 0) {
        const selectedService = ids.map((id) => entities[id]);
        servicesTableContent = ids.map((id) => (
          <ServiceDetailSRow mode={mode} service={entities[id]} />
        ));
        setServicesContent(
          <>
            <span>
              <Button
                leftIcon={
                  <FontAwesomeIcon
                    padding="50px"
                    margin="50px"
                    color="red"
                    icon={faXmark}
                  />
                }
                bg="transparent"
                onClick={() => {
                  clearServices();
                }}
              >
                <span padding="50px" margin="50px" className="small gray">
                  zamknij
                </span>
              </Button>{" "}
            </span>{" "}
            <span>
              <Button
                bg="transparent"
                onClick={() => {
                  dispatch(setSelectedItemDetails(selectedService));
                  console.log("click");
                  dispatch(setItemModalMode("service"));
                  dispatch(setIsItemsModalOpen(true));
                }}
              >
                <FontAwesomeIcon
                  color="gray"
                  marginLeft="15px"
                  icon={faUpRightAndDownLeftFromCenter}
                />
              </Button>
            </span>
            <Box
              marginTop="15px"
              className="itemsBox"
              maxHeight="200px"
              maxWidth={"40vw"}
              overflowX={"auto"}
            >
              <Table>
                <Thead>
                  <Th className="gray" alignContent={"center"} align="center">
                    {" "}
                  </Th>
                  <Th className="gray" alignContent={"center"} align="center">
                    DATA/KLIENT
                  </Th>
                  <Th
                    className="gray"
                    alignContent={"center"}
                    align="center"
                  ></Th>
                  <Th className="gray" alignContent={"center"} align="center">
                    CZĘŚCI
                  </Th>
                  <Th className="gray" alignContent={"center"} align="center">
                    STATUS
                  </Th>
                </Thead>
                <Tbody>{servicesTableContent}</Tbody>
              </Table>
            </Box>
          </>
        );
      }
    }
  }, [services]);

  return <>{servicesContent}</>;
};

export default ServicesContent;
