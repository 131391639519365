import { useDeleteServiceMutation } from "./servicesApiSlice";
import { useDispatch } from "react-redux";
import { setSave } from "./saveServiceSlice";
import { Box, Button, Spinner, useDisclosure } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { EditIcon, CloseIcon } from "@chakra-ui/icons";
import { setMinute } from "../services/selectedMinuteSlice";
import SendEmail from "../mailer/SendEmail";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import { useRef } from "react";

const DelServiceComponent = ({ appointment }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cancelRef = useRef();

  const selectedGarage = useSelector((state) => state.selectedGarage.garage);
  let response = "";
  const selectedStId = useSelector((state) => state.selectedSt.stIds);
  const dispatch = useDispatch();
  const [deleteService, { isLoading, isSuccess, isError, error }] =
    useDeleteServiceMutation();
  const removeS = async (id, st) => {
    console.log("DelServiceComponent");
    // console.log(st);
    console.log("DelServiceComponent");
    const delSt = appointment.st?.map((s) => {
      return { _id: s, toDo: "del" };
    });
    deleteService({ id: appointment._id, st: delSt });
  };
  if (isSuccess) {
    const body = {
      garage: selectedGarage,
      email: appointment?.user?.email,
      date: `${appointment?.date}`,
      hour: appointment?.hour,
      minute: appointment?.minute,
      user: appointment.user?._id,
      notes: "",
    };
    response = <SendEmail task="delete" body={body} />;
    //   // console.log(data);
    // });
    dispatch(setMinute(appointment._id));
  }
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Usuń wizytę
            </AlertDialogHeader>

            <AlertDialogBody>
              Czy usunąć tę pozycję kalendarza?
              {appointment.items.length > 0 && (
                <Box m={3} p={3} bg={"red.100"}>
                  <p className="bold">
                    Do wizyty przypisane były częśći. Jej usunięcie może wpłynąć
                    na stan magazynowy!
                  </p>
                </Box>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Anuluj
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  removeS(appointment.id, appointment.st);
                  onClose();
                }}
                ml={3}
              >
                Usuń
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {isLoading && <Spinner />}
      {isSuccess && response}
      {!isLoading && !isSuccess && (
        <Button
          colorScheme="red"
          margin="0 10px 0 0"
          size="sm"
          onClick={onOpen}
        >
          <CloseIcon w={3} h={3} color="#fff" />
        </Button>
      )}
    </>
  );
};
export default DelServiceComponent;
